<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex class="text-xs-center" mt-5 xs12>
        <WysiwygEditor
            ref="WysiwygEditor"
            :name="'editor'"
            :wysiwyg-content="testcontent"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import WysiwygEditor from "@/components/wysiwyg/WysiwygEditor";

export default {
  components: {WysiwygEditor},
  data() {
    return {
      testcontent: {
        "type": "doc",
        "content": [{"type": "heading", "attrs": {"textAlign": "left", "level": 1}}, {
          "type": "tipTapVueWrapper",
          "attrs": {"model": null}
        }, {
          "type": "heading",
          "attrs": {"textAlign": "left", "level": 1},
          "content": [{"type": "text", "text": "Anmeldung zum Schnuppertag"}]
        }],
        "formModel": [
          {
            "name": "teilnehmer",
            "label": "Teilnehmer",
            "type": "addable",
            subform: [
              {
                "name": "Anrede",
                "label": "Anrede",
                "required": true,
                "rules": "notEmptyRules",
                "type": "select",
                "reference": true,
                "typeName": "Anrede",
                "query": true,
                "listName": "anredes",
                "value": "name",
                "short": "anrede",
                "long": "anrede",
                "model": {"id": false, "anrede": "Anrede"},
                "customWidth": true, "colspan": "2"
              },
              {
                "name": "vorname",
                "label": "Vorname",
                "required": true,
                "rules": "notEmptyRules",
                "customWidth": true,
                "colspan": "5"
              },
              {
                "name": "nachname",
                "label": "Nachname",
                "required": true,
                "rules": "notEmptyRules",
                "customWidth": true,
                "colspan": "5"
              }]
          }
        ]
      }
    }
  }
};
</script>
